import * as Sentry from '@sentry/browser';
import { CaptureConsole, ExtraErrorData } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/tracing';

export class SentryBrowserInitializer {
    static initBrowser(appVersion: string, sentryEnabled: boolean | null, sentryDsn: string | null) {
        if (!sentryEnabled || !sentryDsn) {
            return;
        }

        Sentry.init({
            dsn: sentryDsn,
            tracesSampleRate: 1.0,
            integrations: [
                new CaptureConsole({
                    levels: ['error', 'debug'],
                }),
                new ExtraErrorData(),
                new BrowserTracing(),
            ],
            release: appVersion,
        });

        // https://newscast.flip.de/{{TENANT}}/{{SLUG}}
        const tenant = window.location.href.split('/')[3];
        const slug = window.location.href.split('/')[4];
        Sentry.setTags({
            from: 'browser',
        });

        Sentry.addGlobalEventProcessor((event) => {
            event.extra = {
                tenant,
                slug,
            };
            return event;
        });
    }
}
