import {
    APP_VERSION_TRANSFER_STATE,
    SENTRY_DSN_TRANSFER_STATE,
    SENTRY_ENABLED_TRANSFER_STATE,
} from './shared/constants';
import { Component, OnInit } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { SentryBrowserInitializer } from './../sentry-browser-init';
import { PlatformService } from './shared/services/platform.service';

@Component({
    selector: 'flip-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(private platformService: PlatformService, private transferState: TransferState) {}

    ngOnInit() {
        if (this.platformService.isBrowser) {
            const appVersion = this.transferState.get(APP_VERSION_TRANSFER_STATE, '0.0.0-unkown');
            const sentryEnabled = this.transferState.get(SENTRY_ENABLED_TRANSFER_STATE, null);
            const sentryDsn = this.transferState.get(SENTRY_DSN_TRANSFER_STATE, null);

            SentryBrowserInitializer.initBrowser(appVersion, sentryEnabled, sentryDsn);
        }
    }
}
