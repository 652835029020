import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: ':tenant/:id',
        loadChildren: () => import('./slideshow/slideshow.module').then((m) => m.SlideshowModule),
    },
    {
        path: ':tenant',
        loadChildren: () => import('./root-page/root-page.module').then((m) => m.RootPageModule),
    },
    {
        path: '**',
        loadChildren: () => import('./root-page/root-page.module').then((m) => m.RootPageModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledBlocking',
        }),
        HttpClientModule,
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
